import React from 'react';
import { Link, graphql } from 'gatsby';
import { window } from 'browser-monads';
import Layout from '../components/layout';
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import Card from '../components/card'
import Footer from '../components/footer';
import '../components/blogList/blogList.scss';
import './archive.scss';

const CodePersonal = (props) => {
  const codeContent = props.data.allContentfulCode
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? 'code/personal' : `code/personal/${currentPage - 1}`
  const nextPage = `code/personal/${currentPage + 1}`

  return (
    <Layout>
      <Nav />
      <MobileNav />

      <header>
        <div className='archive__section'>
          <div className='archive__hero'></div>
          <div className='archive__nav'>
            <Link to='/code' className={window.location.href.indexOf('/code') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>All</Link>
            <Link to='code/work' className={window.location.href.indexOf('code/work') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>Work</Link>
            <Link to='code/personal' className={window.location.href.indexOf('code/personal') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>Personal</Link>
          </div>
        </div>
      </header>

      <Card
        sectionData={codeContent}
        sectionCategory="codeCategory"
        sectionLink="/code"
        sectionArchive={true}
      />

      <div className='pagination'>
        <div className='pagination__item'>
          {!isFirst && (
            <Link to={prevPage} rel='prev'>
              <div className='arrow__back'></div>
            </Link>
          )}
        </div>
        <div className='pagination__item'>
          {!isLast && (
            <Link to={nextPage} rel='next'>
              <div className='arrow__next'></div>
            </Link>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default CodePersonal

export const pageQuery = graphql` 
 query CodePersonalQuery ($skip: Int!, $limit: Int!) {
   allContentfulCode(
       sort: { fields: [createdAt], order: DESC }
       filter: {
       node_locale: {eq: "en-US",}
       codeCategory: {code: {elemMatch: {codeCategory: {name: {eq: "Personal"}}}}}
    }
       skip: $skip
       limit: $limit
     ) {
     edges {
       node {
         id
         slug
         title
         createdAt
         codeCategory {
           name
           id
         }
         featuredImage {
           fluid(maxWidth: 1200, quality: 85) {
             src
             ...GatsbyContentfulFluid
           }
         }
       }
     }
   }
 }
`